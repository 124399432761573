<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button @click="goBack()" size="sm" variant="primary">Back</b-button>
    </div>
    <validation-observer ref="GroupAddForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="addPort">
        <b-form-group label="Port" label-for="Port">
          <template #label>
            <span>Port</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <b-form-input
            type="number"
            id="Port"
            v-model.number="port"
            name="port"
          />
        </b-form-group>
        <b-form-group label="Description" label-for="description">
          <b-form-textarea v-model="description"></b-form-textarea>
        </b-form-group>
        <b-form-group label="References" label-for="references">
          <b-form-textarea v-model="references"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Synopsis" label-for="synopsis">
          <b-form-textarea v-model="synopsis"></b-form-textarea>
        </b-form-group>
        <b-form-group label="recommendation" label-for="recommendation">
          <b-form-textarea v-model="recommendation"></b-form-textarea>
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          :disabled="isButtonDisable || port === null"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Add</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    BSpinner,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      //   form: {
      port: null,
      description: "",
      references: "",
      synopsis: "",
      recommendation: "",
      //   },
      isloading: false,
      isButtonDisable: false,
    };
  },
  //   created: function () {
  //     this.load();
  //   },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    addPort: function () {
      this.isButtonDisable = true;
      this.isloading = true;
      //call to axios
      var self = this;
      let formData = new FormData();
      formData.append("port", this.port);
      formData.append("description", this.description);
      formData.append("references", this.references);
      formData.append("synopsis", this.synopsis);
      formData.append("recommendation", this.recommendation);

      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: formData,
        url: process.env.VUE_APP_BASEURL + "asset/insecure-ports/",
      };
      this.$http(options)
        .then((res) => {
          console.log(res);
          if (res.data.id) {
            this.isloading = false;
            this.isButtonDisable = false;
            this.$router.push({ name: "Insecure-port List" });
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfully added Port!",
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.isloading = false;
          this.isButtonDisable = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>